import axios from "../../utils/axios";

//
export function historyList(params) {
    return axios({
        url: "/admin/history/list",
        method: "get",
        params: params
    });
}
