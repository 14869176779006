<template>

  <div>
    <el-form :inline="true" :model="query" class="query-form" size="mini">

      <el-form-item class="query-form-item" label="群组">
        <el-select v-model="query.chat_id">
          <el-option label="全部" :value="0"></el-option>
          <el-option v-for="item in groupList" :label="item.id + '、' + item.title" :value="item.chat_id" :key="item.chat_id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item class="query-form-item" label="搜索的关键字">
        <el-input v-model="query.keyword" placeholder="搜索的关键字"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button-group>
          <el-button type="primary" icon="el-icon-refresh" @click="onReset"></el-button>
          <el-button type="primary" icon="search" @click="onSubmit">查询</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-scrollbar class="table-scrollbar" wrap-style="overflow-x: hidden;">
      <el-table
          v-loading="loading"
          :data="list"
          style="width: 100%;">
        <el-table-column
            label="ID"
            prop="id"
            fixed>
        </el-table-column>
        <el-table-column
            label="群组信息">
          <template slot-scope="scope">
            <div>
              <div class="table-td-item">
                群ID：
                <span>{{scope.row.chat_id || '--'}}</span>
              </div>
              <div class="table-td-item">
                名称：
                <span>{{groupText(scope.row.chat_id)}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="用户信息">
          <template slot-scope="scope">
            <div>
              <div class="table-td-item">
                UserId：
                <span>{{scope.row.user_id || '--'}}</span>
              </div>
              <div class="table-td-item">
                用户名：
                <span>{{scope.row.user_name || '--'}}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="搜索关键字"
            prop="keyword">
        </el-table-column>
        <el-table-column
            :show-overflow-tooltip="true"
            label="发送内容"
            prop="content">
        </el-table-column>
        <el-table-column
            width="180"
            label="创建时间">
          <template slot-scope="scope">
            <span>{{ scope.row.create_time | parseTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            label="更新时间">
          <template slot-scope="scope">
            <span>{{ scope.row.modified_time | parseTime }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>

    <el-pagination
        :page-size="query.limit"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="total">
    </el-pagination>

  </div>

</template>

<script>
import {groupAll} from "../../api/group/group";
import {historyList} from "../../api/group/history";

const formJson = {
  id: "",
  chat_id: "",
  keyword: "",
};
export default {
  computed: {
    groupText() {
      return id => {
        return this.groupMap.get(id)
      }
    },
  },
  data() {
    return {
      query: {
        chat_id: "",
        keyword: "",
        page: 1,
        limit: 20,
      },
      list: [],
      total: 0,
      loading: true,

      groupList: [],
      groupMap: new Map(),
    };
  },
  methods: {
    onReset() {
      this.$router.push({
        path: ""
      });
      this.query = {
        chat_id: "",
        keyword: "",
        page: 1,
        limit: 20,
      };
      this.getList();
    },
    onSubmit() {
      this.$router.push({
        path: "",
        query: this.query
      });
      this.getList();
    },
    handleCurrentChange(val) {
      this.query.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      historyList(this.query)
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
            }
            this.list = response.data.list || [];
            this.total = response.data.total || 0;
          })
          .catch(() => {
            this.loading = false;
            this.list = [];
            this.total = 0;
          });
    },

    getGroupList() {
      groupAll()
          .then(response => {
            this.loading = false;
            if (response.code) {
              this.$message.error(response.message);
              return
            }
            this.groupList = response.data.list || [];
            this.groupMap = new Map()
            for (let item of this.groupList) {
              this.groupMap.set(item.chat_id, item.title)
            }
          })
          .catch(() => {
          });
    },
  },
  filters: {
  },
  mounted() {
  },
  created() {
    // 将参数拷贝进查询对象
    let query = this.$route.query;
    this.query = Object.assign(this.query, query);
    this.query.limit = parseInt(this.query.limit);
    // 加载表格数据
    this.getList();
    this.getGroupList();
  }
};
</script>

<style lang="less" scoped>
</style>
